import { OrgSeasonId, OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";

export async function orgSeason__client__getOrgSeason(p: { orgSeasonId: OrgSeasonId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return await h.OrgSeason.getDoc(p.orgSeasonId);
}

// i18n certified - complete
