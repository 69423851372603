import { OrgId, OrgRegistrationStatus, OrgSeasonId, PlayerBundleId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";

export async function orgSeason__server__getOrgSeasons(p: { orgId: OrgId }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  return (await h.OrgSeason.query({ where: [{ orgId: ["==", p.orgId] }] })).docs;
}

orgSeason__server__getOrgSeasons.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
