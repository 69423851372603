import { OrgSeasonId, OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function orgSeason__client__toggleSeasonState(p: {
  orgSeasonId: OrgSeasonId;
  action: "archive" | "delete" | "restore";
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (p.action === "archive") {
    await h.OrgSeason.update({ id: p.orgSeasonId, doc: { archivedAtMS: Date.now() } });
  } else if (p.action === "restore") {
    await h.OrgSeason.update({ id: p.orgSeasonId, doc: { archivedAtMS: h._MagicDeleteValue } });
  } else if (p.action === "delete") {
    await h.OrgSeason.delete({ id: p.orgSeasonId });
  } else {
    ((a: never) => {})(p.action);
  }
}
