import { OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgSeason__client__getOrgSeasonsForOrgSubscription(p: { orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(h.OrgSeason.querySubscription({ where: [{ orgId: ["==", p.orgId] }] }));
}

// i18n certified - complete
