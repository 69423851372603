import { OrgSeasonId, OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function orgSeason__client__hasRelatedEntities(p: {
  orgSeasonId: OrgSeasonId;
  orgId: string;
  selfAccountId: string;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [a, b, c] = await Promise.all([
    h.OrgRegistration.query({ where: [{ orgId: ["==", p.orgId] }, { orgSeasonId: ["==", p.orgSeasonId] }], limit: 1 }),
    h.OrgInvoice.query({ where: [{ orgId: ["==", p.orgId] }, { orgSeasonId: ["==", p.orgSeasonId] }], limit: 1 }),
    h.OrgRegistrationPackage.query({ where: [{ orgId: ["==", p.orgId] }, { orgSeasonId: ["==", p.orgSeasonId] }], limit: 1 })
  ]);

  return !!(a.docs.length + b.docs.length + c.docs.length);
}

// i18n certified - complete
