import { OrgSeason } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";

export async function orgSeason__client__updateOrgSeason(p: { orgSeason: OrgSeason }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.OrgSeason.set({
    id: p.orgSeason.id,
    doc: { ...p.orgSeason, updatedAtMS: Date.now() }
  });
}

// i18n certified - complete
