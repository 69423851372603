import { OrgSeasonId, OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftDocsSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgSeason__client__getMultiOrgSeasonsSubscription(p: { orgSeasonIds: OrgSeasonId[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocsSubToBifrostSub(h.OrgSeason.docsSubscription(p.orgSeasonIds));
}

// i18n certified - complete
