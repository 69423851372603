import { OrgId, OrgRegistrationStatus, OrgSeasonId, PlayerBundleId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";

export async function orgSeason__server__getOrgSeasonMoneyTotals(p: { orgSeaonsId: OrgSeasonId; orgId: OrgId }) {
  const { getAppPgPool } = getServerHelpers();

  const r1 = await getAppPgPool().query(
    `select *
  from f_org_season_total_money_summary($2)
  where org_season_id = $1 limit 1;`,
    [p.orgSeaonsId, p.orgId]
  );

  if (!r1.rowCount) {
    return null;
  }
  return {
    orgId: r1.rows[0]["org_id"],
    orgSeasonId: r1.rows[0]["org_season_id"],
    amountExpectedTotalCents: parseInt(r1.rows[0]["amount_expected_total_cents"]),
    defaultAmountPaidCents: parseInt(r1.rows[0]["default_paid_amount_cents"]),
    creditAmountCents: parseInt(r1.rows[0]["credit_amount_cents"]),
    totalPaidIncludingCreditsCents: parseInt(r1.rows[0]["total_paid_including_credits_cents"]),
    lateFeeAmountPaidCents: parseInt(r1.rows[0]["late_fee_amount_paid_cents"]),
    amountScheduledCents: parseInt(r1.rows[0]["amount_scheduled_cents"])
  };
}

orgSeason__server__getOrgSeasonMoneyTotals.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
