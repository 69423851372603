import { OrgId, OrgRegistrationStatus, OrgSeasonId, PlayerBundleId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";

export async function orgSeason__server__getActiveOrgSeasonsForOrgIds(p: { orgIds: OrgId[] }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const nowMS = Date.now();
  const results = (
    await h.OrgSeason.multiQuery({
      queries: p.orgIds.map(orgId => {
        return {
          where: [
            { orgId: ["==", orgId] },
            {
              startDateMS: ["<", nowMS]
            },
            {
              endDateMS: [">", nowMS]
            }
          ]
        };
      })
    })
  ).docs;
  return results.filter(os => !os.archivedAtMS);
}

orgSeason__server__getActiveOrgSeasonsForOrgIds.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
