import { OrgSeasonId, OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgSeason__client__getOrgSeasonSubscription(p: { orgSeasonId: OrgSeasonId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocSubToBifrostSub(h.OrgSeason.docSubscription(p.orgSeasonId));
}

// i18n certified - complete
