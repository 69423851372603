import { OrgSeason, OrgSeasonId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit, computeRollOverPackageInfo } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";

export async function orgSeason__client__addOrgSeason(p: {
  orgSeason: DistributiveOmit<OrgSeason, "id" | "createdAtMS">;
  orgSeasonIdForRegistrationPackageDuplication?: OrgSeasonId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const newOrgSeasonId = h.OrgSeason.generateId();

  const newOrgSeason: OrgSeason = { ...p.orgSeason, id: newOrgSeasonId, createdAtMS: Date.now() };

  const batchTasks: BatchTask[] = [];

  batchTasks.push(
    await h.OrgSeason.add(
      {
        doc: newOrgSeason
      },
      { returnBatchTask: true }
    )
  );

  if (p.orgSeasonIdForRegistrationPackageDuplication) {
    const orgRegistrationPackagesToDuplicate = (
      await h.OrgRegistrationPackage.query({
        where: [
          {
            orgSeasonId: ["==", p.orgSeasonIdForRegistrationPackageDuplication]
          },
          { orgId: ["==", p.orgSeason.orgId] }
        ]
      })
    ).docs;
    for (let i = 0; i < orgRegistrationPackagesToDuplicate.length; i++) {
      const orgRegistrationPackageToDuplicate = orgRegistrationPackagesToDuplicate[i];
      const { name: newName, birthYears: newBirthYears } = computeRollOverPackageInfo(orgRegistrationPackageToDuplicate);
      batchTasks.push(
        await h.OrgRegistrationPackage.add(
          {
            doc: {
              ...orgRegistrationPackageToDuplicate,
              name: newName,
              id: h.OrgRegistrationPackage.generateId(),
              createdAtMS: Date.now(),
              updatedAtMS: h._MagicDeleteValue,
              orgSeasonId: newOrgSeasonId,
              birthYears: newBirthYears
            }
          },
          { returnBatchTask: true }
        )
      );
    }
  }

  await h._BatchRunner.executeBatch(batchTasks);

  return newOrgSeasonId;
}

// i18n certified - complete
